var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0 elevation-0 white",staticStyle:{"max-height":"100%"}},[_c('div',{staticClass:" my-border"},[_c('v-card-title',{staticClass:"py-0 my-0 customlightgray"},[_c('v-row',{staticClass:"ma-0 pa-0 py-2 justify-center text-left"},[_c('v-col',{staticClass:"pa-0 ma-auto",attrs:{"cols":"6","sm":"4","md":"4","lg":"4","order":"1","order-md":"1"}},[_c('div',{staticClass:"font-weight-medium text-left text--secondary"},[_c('div',{staticClass:"ma-auto pa-auto"},[_c('span',{staticClass:"ma-0 pa-1"},[_vm._v(_vm._s(_vm.$t('Purge Request')))]),_c('span',{staticClass:"custom-color-accent pa-1",staticStyle:{"border-radius":"22px","font-size":"0.6em"}},[_vm._v(_vm._s(_vm.getPurgeRequest.length)+" ")])])])]),_c('v-col',{staticClass:"ma-0 pa-0 text-center",attrs:{"order":"3","order-md":"2","cols":"12","sm":"12","md":"4","lg":"4"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"dense":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search.searchWord),callback:function ($$v) {_vm.$set(_vm.search, "searchWord", $$v)},expression:"search.searchWord"}})],1),_c('v-col',{staticClass:"text-right pa-0 my-2 ma-md-auto",attrs:{"cols":"6","sm":"8","md":"4","lg":"4","order":"2","order-md":"3"}})],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0 customoffwhite text--secondary pa-0 ma-0 ",attrs:{"mobile-breakpoint":1081,"headers":_vm.headers,"items":_vm.getPurgeRequest,"search":_vm.search.searchWord,"items-per-page":_vm.itemsPerPpage,"loading-text":_vm.$t('Loading'),"loading":_vm.loading,"footer-props":{
          'items-per-page-text':_vm.$t('rowsperpage')
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noDataAvailable')))]},proxy:true},{key:"item.DateReceived",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.DateReceived)))]}},{key:"item.DateDue",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.calculateDueDate(item)))+" ")]}},{key:"item.DateOfClosure",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.DateOfClosure)))]}},{key:"item.lastAction",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.getLastAction(item)+' '+_vm.$t('Days')))]}},{key:"item.ATIPOfficerId",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.getUser(item.ATIPOfficerId)))])]}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.address.FirstName+' '+item.address.LastName))])]}},{key:"item.statuslang",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"pa-0 ma-0 mx-1 justify-center status-chip",attrs:{"small":"","color":_vm.setcolor(item.statuslang)}},[_vm._v(" "+_vm._s(_vm.getStatus(item))+" ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0 text-right"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewRequest(item)}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actions),function(action,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.setAction(item, action)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(action.text)))])],1)}),1)],1)],1)],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }