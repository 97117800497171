<template>
<v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{$t('Purge Request')}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{getPurgeRequest.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  ></v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
            </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
        :headers="headers"
        :items="getPurgeRequest"
        :search="search.searchWord"
        :items-per-page="itemsPerPpage"
        class="elevation-0 customoffwhite text--secondary pa-0 ma-0 "
        :loading-text="$t('Loading')"
        :loading="loading"
        :footer-props="{
          'items-per-page-text':$t('rowsperpage')
        }"
      >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template v-slot:[`item.DateReceived`]="{item}">{{ item.DateReceived | formatDate }}</template>
        <template v-slot:[`item.DateDue`]="{item}">
          {{ calculateDueDate(item) | formatDate }}
        </template>
        <template v-slot:[`item.DateOfClosure`]="{item}">{{ item.DateOfClosure | formatDate }}</template>
        <template v-slot:[`item.lastAction`]="{item}">{{ getLastAction(item)+' '+$t('Days') }}</template>
        <template v-slot:[`item.ATIPOfficerId`]="{item}"><span class="text-capitalize">{{ getUser(item.ATIPOfficerId) }}</span></template>
        <template v-slot:[`item.address`]="{item}"><span class="text-capitalize">{{ item.address.FirstName+' '+item.address.LastName }}</span></template>
        <template v-slot:[`item.statuslang`]="{ item }">
            <v-chip 
              class="pa-0 ma-0 mx-1 justify-center status-chip"
              small
              :color='setcolor(item.statuslang)'
            >
                {{ getStatus(item) }}
            </v-chip>
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-btn icon @click="viewRequest(item)"><v-icon>mdi-open-in-new</v-icon></v-btn>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on.stop="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(action, i) in actions" :key="i" @click="setAction(item, action)">
                    <v-list-item-title>{{ $t(action.text) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import {  mapState, mapActions , mapGetters } from 'vuex';
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['getPurgeRequest'],
  data() {
    return {
      itemsPerPpage:10,
      search: {
        searchWord: ""
      },
      selectedHeaderAction:false,
      headers: [
        {
          text: this.$t('ID'),
          align: "left",
          sortable: true,
          value: "reqCode"
        },
        { text: this.$t('status'), value: "statuslang" },
        { text: this.$t('requestor'), value: "address" },
        { text: this.$t('assignee'), value: "ATIPOfficerId" },
        { text: this.$t('lastActionDays'), value: "lastAction" },
        { text: this.$t('received'), value: "DateReceived"},
        { text: this.$t('closed'), value: "DateOfClosure"},
        { text: this.$t('due'), value: "DateDue"},
        { text: "", value: "action", sortable: false }
      ],
      actions: [
        { title: "Dispose level 1", text:'Dispose level 1', role: 'officer' },
        { title: "Dispose level 2", text:'Dispose level 2', role: 'officer' },
        { title: "Dispose level 3", text:'Dispose level 3', role: 'officer' }
      ],
    };
  },
  watch: {

  },
  async created() {
  },

  computed: {

    ...mapState('request', {
      loading: 'loading'
    }),
      ...mapGetters({
      getTypes:"getTypes"
    }),

    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),
      ...mapState({
        users: "users"
      }),
  },

  methods: {
    ...mapActions('request', ['retriveRequests']),
    getLastAction(item){
      return moment().diff(item.updated_at, "days")
    },
    getStatus(request){
      request.statuslang = localStorage.getItem('language') === 'en' ?
            this.configurations.filter(i=>i.LookUpKey === request.RequestStatus)[0]?.LookUpStringE :
            this.configurations.filter(i=>i.LookUpKey === request.RequestStatus)[0]?.LookUpStringF;
    },
    getUser(id){
      return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0]
    },
    viewRequest(req){
      this.$router.push({name: 'requests',params: { id: req.id }})
    },
     calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      //req.DateDue=date;
      return date;
    },
    setcolor(status){
      if(status==='Request Closed'){
        return 'error';
      }
      else if (status==='On Hold'){
        return 'warning';
      }
    
      else if(status==='New'){
        return 'custom-color-accent'
      }
      else{
          return "gray";
      }
    },
    setAction(item, action) {
      switch (action.title) {
        case "Dispose level 1":
          try {
            axcessApi.deleteRequest("/request/" + item.id);
            this.retriveRequests();
            this.dialogDelete = false;
          } catch (error) {
            this.error = error;
            this.errored = true;
          }
          break;
        case "Dispose level 2":
          try {
            axcessApi.deleteRequest("/request/" + item.id);
            this.retriveRequests();
            this.dialogDelete = false;
          } catch (error) {
            this.error = error;
            this.errored = true;
          }
          break;
        case "Dispose level 3":
          try {
            axcessApi.deleteRequest("/request/" + item.id);
            this.retriveRequests();
            this.dialogDelete = false;
          } catch (error) {
            this.error = error;
            this.errored = true;
          }
          break;
        default:
        // code block
      }
    },


  }
};
</script>

<style lang="scss" scoped>
.row {
  cursor: pointer;
}
.status-chip{
  min-width: 180px;
}
</style>