<template>
  <div class="request">
    <purge-requests-table :getPurgeRequest="getPurgeRequest?getPurgeRequest:[]"></purge-requests-table>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import PurgeRequestsTable from "../components/admin/PurgeRequestsTable";

export default {
  name: "PurgeRequestTable",

  data() {
    return {
    };
  },

  components: {
    PurgeRequestsTable
  },
  async created() {
    await this.retriveRequests();
    await this.retriveUsers();
    await this.$store.dispatch("configuration/retriveConfigurations");
  },

  methods: {
    ...mapActions('request', ['retriveRequests']),
    ...mapActions([ "retriveUsers","retriveTypes"]),
  },

  computed: {
     ...mapState('request', {
      requests: 'requests'
    }),
    ...mapGetters('request', {
      getPurgeRequest: 'getPurgeRequest'
    }),
  }
};
</script>
